import baseDialog from './components/baseDialog.vue'
import vueTinymce from './components/vueTinymce.vue'
import basePagination from './components/basePagination.vue'
import headerSelect from './components/headerSelect.vue'

// 全局注册组件
export default {
  install: function (Vue) {
    Vue.component('base-dialog', baseDialog)
    Vue.component('vue-tinymce', vueTinymce)
    Vue.component('base-pagination', basePagination)
    Vue.component('headerSelect', headerSelect)
  }
}
