<template>
  <div @click.stop="">
    <el-dialog :title="`选择就餐人数（${activeParsonNum||0}）人`" :visible.sync="dialogVisible">
      <div class="addOrderDialog__body">
        <el-scrollbar style="height: 100%">
          <div
          @click="activeParsonNum = item"
            :class="{
              'addOrderDialog__body--item': true,
              'addOrderDialog__body--active':item ===activeParsonNum
            }"
            :key="item"
            v-for="item in parsonNum"
          >
            {{ item }}
          </div>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="activeParsonNum!==''" @click="setSuccess">确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: false,
      show: false,
      parsonNum: 0,
      activeParsonNum: '',
      successCD: ''
    }
  },
  watch: {
      dialogVisible () {
        if (!this.dialogVisible) {
          setTimeout(() => {
              this.cancel()
          }, 1000)
        }
      }
  },
  mounted () {
    this.dialogVisible = this.show
  },
  methods: {
    setSuccess () {
      this.successCD(this.activeParsonNum)
       this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.addOrderDialog {
  &__body {
    height: 500px;
    &--item {
      display: inline-block;
      width: 186px;
      height: 186px;
      background: #f7f7fc;
      margin-left: 40px;
      margin-bottom: 25px;
      cursor: pointer;
      text-align: center;
      line-height: 186px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 30px;
    }
    &--active{
      color: #fff;
      background-color: #ffbb12;
    }
  }
}
::v-deep .el-scrollbar {
  overflow-y: hidden !important;
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto !important;
}
::v-deep .is-horizontal {
  display: none;
}
</style>
