import request from '@/utils/request.js'
export default {
  // 用户登录
  businessInfoSave: (data) => {
    return request({
      url: '/business/businessInfo/save',
      data,
      method: 'post'
    })
  },
  // 查询菜品列表
  businessInfoFoodList: (data) => {
    return request({
      url: '/business/foodInfo/foodList',
      data,
      method: 'post'
    })
  },
  // 查询菜品列表
  businessInfoPage: (data) => {
    return request({
      url: '/business/businessInfo/page',
      data,
      method: 'post'
    })
  }
}
