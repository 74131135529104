import auth from '@/service/auth.js'
import mealApp from '@/service/meal-app.js'
import businessUser from '@/service/businessUser.js'
import businessInfo from '@/service/businessInfo.js'
import router from '@/router/index.js'
import ws from '@/utils/ws.js'
import { Notification } from 'element-ui'
const state = {
  userInfo: {},
  shopInfo: {},
  breadcrumb: [],
  socketTask: null,
  newMsg: false,
  message: null
}

const mutations = {
  setUserInfo (state, data) {
    state.userInfo = data
  },
  setNewMsg (state, data) {
    state.newMsg = data
  },
  setLoading (state, data) {
    state.loading = data
  },
  setShopInfo (state, data) {
    state.shopInfo = data
    // if (Object.keys(data).length > 0) {
    //   this.commit('login/setSocketTask')
    // }
  },
  setBreadcrumb (state, data) {
    state.shopInfo = data
  },
  setSocketTask (state) {
    if (state.socketTask && state.socketTask.socketTask) {
      state.socketTask.socketTask.close()
    }
    // eslint-disable-next-line new-cap
    state.socketTask = new ws({
      roomId: state.shopInfo.id,
      onMessageCD: (e) => {
        console.log(e)
        if (e.data === '连接成功') {
          return
        }
        const formData = JSON.parse(e.data)
        // 订单相关推送
        if (formData.type === 'newOrder' || formData.type === 'cancelOrder') {
          state.newMsg = true
          state.message = formData
          // 顾客退单
          if (formData.type === 'cancelOrder') {
            const NotificationMsg = {}
            NotificationMsg.title = '取消订单'
            NotificationMsg.message = `${formData.businessTableMark} 申请退单，请尽快处理`
            NotificationMsg.type = 'warning'
            Notification(NotificationMsg)
          }
        }
      }
    })
    console.log(state.socketTask)
  }
}

const actions = {
  async getUserInfo (context) {
    // 存在用户信息
    if (Object.keys(state.userInfo).length > 4) {
      return
    }
    console.log(111)
    // 获取用户详情
    const res = await auth.getUserInfo()

    // 判断是否更改过初始密码
    const businessVerify = await auth.businessVerify(res.data.phone)

    // 初始密码
    if (businessVerify.data) {
      // uni.$u.route({
      //   type: 'reLaunch',
      //   url: 'shopGm/changePassword/index',
      //   params: {
      //     businessVerify: businessVerify.data
      //   }
      // })
      return
    }

        // 有登录信息
    if (res.data.lastLoginBusinessId) {
      const shopData = await mealApp.getBusinessInfo(
        res.data.lastLoginBusinessId
      )
      const shopUser = await businessUser.getBusinessUser({
        businessId: res.data.lastLoginBusinessId,
        businessUserId: res.data.id
      })
      context.commit('setShopInfo', shopData.data)
      context.commit('setUserInfo', {
        ...res.data,
        businessUserType: shopUser.data.businessUserType,
        businessUserId: res.data.id
      })
      return res
    }
    // 获取用户拥有的商铺
    const businessInfoRes = await businessInfo.businessInfoPage({
      businessUserId: res.data.id,
      pageNum: 1,
      pageSize: 30
    })

    // 没有店铺
    if (!businessInfoRes.data.total === 0) {
      context.commit('setUserInfo', res.data)
      return
    }

    // 获取商品列表第一家的商铺
    const shopData = await mealApp.getBusinessInfo(
      businessInfoRes.data.list[0].id
    )
    // 获取用户类型
    const shopUser = await businessUser.getBusinessUser({
      businessId: shopData.data.id,
      businessUserId: res.data.id
    })
    // 保存第一次登录的店铺
    await businessUser.saveLastLogin({
      businessUserId: res.data.id,
      businessId: shopData.data.id
    })
    context.commit('setShopInfo', shopData.data)
    context.commit('setUserInfo', {
      ...res.data,
      businessUserType: shopUser.data.businessUserType,
      businessUserId: res.data.id
    })
    return res
  },
  goQuit (context) {
    window.sessionStorage.removeItem('token')
    context.commit('setUserInfo', {})
    context.commit('setShopInfo', {})
    router.replace({
      path: '/login',
      query: {}
    })
    state.socketTask.socketTask.close()
  }
}

const getters = {
  /* 获取token */
  getToken (state) {
    return state.token
  },
  /* 获取角色 */
  getRoles (state) {
    return state.roles
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
