import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import buyCard from './modules/buyCard.js'
import permissions from './modules/permissions'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    login,
    permissions,
    buyCard
  }
})

export default store
