export default [
  {
    path: '/layout/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: '首页',
      imgSrc: require('@/assets/home.png'),
      imgSrcNot: require('@/assets/homeNot.png'),
      permissions: ['home'],
      breadNumber: 1,
      show: true
    }
  },
  {
    path: '/layout/order',
    name: 'order',
    component: () => import('@/views/order/index.vue'),
    meta: {
      title: '订单',
      imgSrc: require('@/assets/order.png'),
      imgSrcNot: require('@/assets/orderNot.png'),
      permissions: ['order'],
      breadNumber: 1,
      show: true
    }
  },
  {
    path: '/layout/summary',
    name: 'summary',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: '报表',
      imgSrc: require('@/assets/summary.png'),
      imgSrcNot: require('@/assets/summaryNot.png'),
      permissions: ['summary'],
      show: true
    }
  },
  {
    path: '/layout/management',
    name: 'management',
    component: () => import('@/views/management/index.vue'),
    meta: {
      title: '管理',
      imgSrc: require('@/assets/management.png'),
      imgSrcNot: require('@/assets/managementNot.png'),
      permissions: ['management'],
      breadNumber: 1,
      show: true
    }
  },
  {
    path: '/layout/shop',
    name: 'shop',
    component: () => import('@/views/shop/index.vue'),
    meta: {
      title: '点餐',
      imgSrc: require('@/assets/management.png'),
      imgSrcNot: require('@/assets/managementNot.png'),
      permissions: ['shop'],
      breadNumber: 2,
      show: false
    }
  },
  {
    path: '/layout/foodGm',
    name: 'foodGm',
    component: () => import('@/views/foodGm/index.vue'),
    meta: {
      title: '菜品管理',
      imgSrc: require('@/assets/management.png'),
      permissions: ['foodGm'],
      breadNumber: 2,
      show: false
    }
  },
  {
    path: '/layout/tableGm',
    name: 'tableGm',
    component: () => import('@/views/tableGm/index.vue'),
    meta: {
      title: '桌台管理',
      imgSrc: require('@/assets/management.png'),
      permissions: ['tableGm'],
      breadNumber: 2,
      show: false
    }
  },
  {
    path: '/layout/spendingGm',
    name: 'spendingGm',
    component: () => import('@/views/spendingGm/index.vue'),
    meta: {
      title: '支出管理',
      imgSrc: require('@/assets/management.png'),
      permissions: ['spendingGm'],
      breadNumber: 2,
      show: false
    }
  }
]
