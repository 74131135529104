import request from '@/utils/request.js'

export default {
  // 用户登录
  appBusinessLogin: (data) => {
    return request({
      url: '/auth/businessLogin',
      data,
      method: 'post'
    })
  },
  // 获取用户信息
  getUserInfo: (data) => {
    return request({
      url: '/auth/getBusinessUserInfo',
      data,
      method: 'post'
    })
  },
  // 第三方平台登陆
  thirdpartyLogin: (data) => {
    return request({
      url: '/auth/businessThirdpartyLogin',
      data,
      method: 'post'
    })
  },
  // 第三方平台绑定并登录
  thirdpartyBindLogin: (data) => {
    return request({
      url: '/auth/businessThirdpartyBindLogin',
      data,
      method: 'post'
    })
  },
  // 验证初始密码
  businessVerify: (username) => {
    return request({
      url: '/auth/businessVerify/' + username,
      method: 'post'
    })
  },
  updateByVerify: (data) => {
    return request({
      url: '/auth/updateByVerify',
      data,
      method: 'post'
    })
  }
}
