class Debounce {
  constructor () {
    this.setTimeout = null
  }

  debounceCD (fun, wait) {
    if (this.setTimeout) {
      clearTimeout(this.setTimeout)
    }
    this.setTimeout = setTimeout(() => {
      fun()
    }, wait)
  }
}

export default Debounce
