const state = {
  card: [], // 购物车数据
  checkin: [], // 需要付款的phone
  checkout: [] // 不需要付款的phone
}

const mutations = {
  resetCardPhone (state) {
    const card = []

    state.card.forEach(item => {
      if (!state.checkin.includes(item.phone)) {
        card.push(item)
      }
    })

    state.card = card
  },
  resetCard (state, phone) {
    state.card = []
  },
  // 增加商品
  setCard (state, data) {
    data.phone = 'admin'
    const obj = {}
    obj.phone = data.phone
    obj.ls = []
    let count = '' // 商品数量

    // eslint-disable-next-line no-unused-vars
    const Ls = []

    // 没有属性
    if (!data.foodProp || data.foodProp.length === 0) {
      // 获取当前购买的商品的人员是否存在
      const lsItem = state.card.length > 0 ? state.card.find(item => item.phone === data.phone) : null

      // 获取当前购买的商品是否之前已经存在
      const findItem = lsItem ? lsItem.ls.find(item => item.id === data.id) : null

      // 商品不存在数量默认为0
      count = findItem ? findItem.count : data.count || 1

      // 获取商店数据里面不为当前商品的数据,并且数量不为0的
      if (findItem) {
        if (lsItem && lsItem.ls.length > 0) {
          lsItem.ls.forEach(item => {
            if (item.id !== data.id && item.count !== 0) {
              obj.ls.push(item)
            }
            if (item.id === data.id && item.count !== 0) {
              obj.ls.push({
                ...data,
                count: count + 1
              })
            }
          })
        }
      } else {
        obj.ls = lsItem ? lsItem.ls.filter(item => item.id !== data.id && item.count !== 0) : []
        obj.ls.push({
          ...data,
          count: count
        })
      }
    } else {
      // 获取当前购买的商品的人员是否存在
      const lsItem = state.card.length > 0 ? state.card.find(item => item.phone === data.phone) : null

      // 获取当前购买的商品是否之前已经存在
      const findItem = lsItem ? lsItem.ls.find(item => item.id === data.id && item.foodProp === data.foodProp) : null

      // 当前商品不存在
      if (!findItem) {
        obj.ls = lsItem ? lsItem.ls : []
        count = data.count || 1
        obj.ls.push({
          ...data,
          count: count
        })
      }
      if (findItem) {
        const list = lsItem ? lsItem.ls : []
        count = findItem.count + 1
        list.forEach(item => {
          if ((item.id === data.id && item.foodProp === data.foodProp) && item.count !== 0) {
            obj.ls.push({
              ...data,
              count: count
            })
          }
          if ((item.id !== data.id || item.foodProp !== data.foodProp) && item.count !== 0) {
            obj.ls.push(item)
          }
        })
      }
    }
    const exist = state.card.some(item => item.phone === data.phone)
    if (exist) {
      state.card = state.card.map(item => {
        if (item.phone === data.phone) {
          return obj
        }
        return item
      })
    } else {
      state.card = [...state.card, obj]
    }
    console.log('card', state.card)
    state.card.forEach(item => {
      // 需要排除的数组没有当前数据
      if (!state.checkout.includes(item.phone) && !state.checkin.includes(item.phone)) {
        state.checkin = [...state.checkin, item.phone]
      }
    })
  },
  // 减少商品
  reduceCard (state, data) {
    data.phone = 'admin'

    const Item = state.card.find(item => item.phone === data.phone)
    const ItemLs = Item ? Item.ls : []

    const Ls = []
    const obj = {}
    obj.phone = data.phone
    obj.ls = []

    const foodProp = typeof data.foodProp === 'string' ? JSON.parse(data.foodProp) : data.foodProp

    if (!data.foodProp || (foodProp && foodProp.length === 0)) {
      ItemLs.forEach(item => {
        if (item.id === data.id) {
          item.count = item.count - 1
        }
        if (item.count !== 0) {
          Ls.push(item)
        }
      })
    } else {
      ItemLs.forEach(item => {
        if (item.id === data.id && item.foodProp === data.foodProp) {
          item.count = item.count - 1
        }
        if (item.count !== 0) {
          Ls.push(item)
        }
      })
    }
    obj.ls = Ls
    state.card = state.card.map(item => {
      if (item.phone === data.phone) {
        return obj
      }
      return item
    })
    console.log(state.card)
  },
  addCheckout (state, newChick) {
    newChick.forEach(item => {
      if (!state.checkout.includes(item)) {
        state.checkout = [...state.checkout, item]
      }
    })
  },
  reduceCheckout (state, newChick) {
    const checkout = []
    state.checkout.forEach(item => {
      if (!newChick.includes(item)) {
        checkout.push(item)
      }
    })
    state.checkout = checkout
  },
  setCheckin (state, newChick) {
    state.checkin = [...newChick]
  }
}
const getters = {
  getCard (state) {

  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
