import router from '@/router/index'
import store from '@/store/index'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
/* 全局路由导航守卫 */
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (!window.sessionStorage.getItem('token')) {
    if (to.path === '/login') {
      next()
    } else {
      next({ path: '/login' })
    }
  } else {
    if (Object.keys(store.state.login.userInfo).length > 0) {
      const find = to.meta.permissions && to.meta.permissions.some((router) =>
      store.state.permissions.permissions.includes(router)
    )
      if (find || !to.meta.permissions) {
        next()
      } else {
        Message({
         message: '该功能暂未开放',
         type: 'warning'
       })
       NProgress.done()
       next(false)
     }
    } else {
      if (to.path === '/login') {
        next()
      }
      const res = await store.dispatch('login/getUserInfo')
     if (res.data) {
      next()
     }
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
