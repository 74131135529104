<template>
  <div class="headerSelect">
    <el-row>
      <el-col :span="24">
        <el-form
          ref="form"
          :model="formData"
          label-width="100px"
          :inline="true"
        >
          <el-form-item label="校区" v-if="isSelectItem('campus')">
            <el-select v-model="formData.campusId" @change="selectHandle">
              <el-option
                v-for="(item, index) in campusIdList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="院系" v-if="isSelectItem('college')">
            <el-select v-model="formData.collegeId" @change="selectHandle">
              <el-option
                v-for="(item, index) in collegeList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  /**
   * @description 用于头部下拉过滤选择
   * @property {Array} selectItem 显示选择器
   * @event change 选择事件
   */
  props: {
    selectItem: {
      type: Array,
      default: () => {
        return ['campus', 'college']
      }
    }
  },
  data () {
    return {
      formData: {
        campusId: '',
        collegeId: ''
      },
      campusIdList: [{
        id: '',
        name: '全部'
      }, {
        id: 1,
        name: '东校区'
      }, {
        id: 2,
        name: '南校区'
      }],
      collegeList: [{
        id: '',
        name: '全部'
      }, {
        id: 1,
        name: '信息工程学院'
      }, {
        id: 2,
        name: '外语学院'
      }]
    }
  },
  computed: {
    isSelectItem () {
      return (item) => {
        return this.selectItem.includes(item)
      }
    }
  },
  methods: {
    // 选择事件
    selectHandle () {
      this.$emit('change', this.formData)
    }
  }
}
</script>

<style>
</style>
