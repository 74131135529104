let host = ''
let file = ''
let wsUrl = ''
if (process.env.NODE_ENV === 'development') {
  // // eslint-disable-next-line no-tabs
  // host = 'http://192.168.31.68:11001/meal-plus'
  // // eslint-disable-next-line no-tabs
  // file = 'http://file-static.cx-online.net'
  // // eslint-disable-next-line no-tabs
  // wsUrl = 'ws://202.104.205.81:11001'
  // eslint-disable-next-line no-tabs
  host = 'https://api.yidiancan.net/meal-plus'
  // eslint-disable-next-line no-tabs
  file = 'http://file-static.cx-online.net/'
  // eslint-disable-next-line no-tabs
  wsUrl = 'wss://api.yidiancan.net'
} else {
  // eslint-disable-next-line no-tabs
  host = 'https://api.yidiancan.net/meal-plus'
  // eslint-disable-next-line no-tabs
  file = 'http://file-static.cx-online.net/'
  // eslint-disable-next-line no-tabs
  wsUrl = 'wss://api.yidiancan.net'
}

export { host, file, wsUrl }
