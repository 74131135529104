import request from '@/utils/request.js'

export default {
  // 保存最后一次登录
  saveLastLogin: (data) => {
    return request({
      url: '/business/businessUser/saveLastLogin',
      data,
      method: 'post'
    })
  },
  // 保存用户
  businessUserSaveUser: (data) => {
    return request({
      url: '/business/businessUser/save',
      data,
      method: 'post'
    })
  },
  // 分页
  businessUserPage: (data) => {
    return request({
      url: '/business/businessUser/page',
      data,
      method: 'post'
    })
  },
  // 查询职员详情
  getBySysUserId: (id) => {
    return request({
      url: '/business/businessUser/getById/' + id,
      method: 'post'
    })
  },
  // 删除职员详情
  businessUserDel: (id) => {
    return request({
      url: '/business/businessUser/del/' + id,
      method: 'post'
    })
  },

  // 获取商家用户
  getBusinessUser: (data) => {
    return request({
      data,
      url: '/business/businessUser/getBusinessUser',
      method: 'post'
    })
  },

  // 修改商家用户密码
  updateBUPassword: (data) => {
    return request({
      data,
      url: '/business/businessUser/updateBUPassword',
      method: 'post'
    })
  }
}
