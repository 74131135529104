import { file } from '@/utils/url.js'
import printerInfo from '@/service/printerInfo.js'
export default {
  data () {
    return {
      imgUrl: file
    }
  },
  methods: {
    async printOrder (data) {
      let printOrderRes = ''
      // 获取打印机详情
      // data.voice
      const formData = {
        orderId: data.orderId,
        voice: data.voice
      }
      // 类型为1 或者 没有类型 打印新菜品
      if (data.type === 1 || !data.type) {
         printOrderRes = await printerInfo.printAddOrder(formData)
      } else {
        printOrderRes = await printerInfo.printOrder(formData)
      }
      return printOrderRes
    }
  }
}
