
const state = {
  permissions: ['home', 'order', 'shop', 'management', 'foodGm', 'tableGm', 'spendingGm']

}

const mutations = {

}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
