<template>
  <div class="scanner">
    <div class="qr-scanner" v-if="!loading">
      <div class="tips">扫描期间请勿触碰键盘或鼠标！</div>
      <div class="box">
        <div class="line"></div>
        <div class="angle"></div>
      </div>
      <div class="danger">
        <el-button type="danger" @click="cancelPay">取消扫描</el-button>
      </div>
    </div>
    <i class="el-icon-loading" v-else></i>
    <input type="text" id="dlKeyword" value="" />
  </div>
</template>
<script>
import Debounce from '@/utils/debounce.js'
const DebounceFun = new Debounce()
export default {
  data () {
    return {
      orderId: '',
      loading: false,
      invoicingPay: false,
      invoicingCd: null,
      createOrder: false,
      addFood: false,
      orderData: {},
      successCD: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      const dom = document.getElementById('dlKeyword')
      if (dom) {
        document.onkeydown = this.keywordFun
        dom.value = ''
        dom.focus()
      }
    })
  },
  methods: {
    keywordFun (e) {
      DebounceFun.debounceCD(async () => {
        this.loading = true
        const dom = document.getElementById('dlKeyword')
        this.successCD(dom.value)
      }, 200)
    },
    // 取消付款
    async cancelPay () {
      document.onkeydown = null
      this.cancel()
    }
  }
}
</script>
<style lang="scss" scoped>
.qr-scanner {
  position: relative;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      rgba(32, 255, 77, 0.1) 25%,
      rgba(32, 255, 77, 0.1) 26%,
      transparent 27%,
      transparent 74%,
      rgba(32, 255, 77, 0.1) 75%,
      rgba(32, 255, 77, 0.1) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(32, 255, 77, 0.1) 25%,
      rgba(32, 255, 77, 0.1) 26%,
      transparent 27%,
      transparent 74%,
      rgba(32, 255, 77, 0.1) 75%,
      rgba(32, 255, 77, 0.1) 76%,
      transparent 77%,
      transparent
    );
  background-size: 3rem 3rem;
  background-position: -1rem -1rem;
  width: 300px;
  height: 300px;
  position: relative;
  background-color: #111;
}

.qr-scanner .box {
  width: 300px;
  height: 300px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border: 0.1rem solid rgba(0, 255, 51, 0.2);
}

.qr-scanner .line {
  height: calc(100% - 2px);
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #00ff33 211%);
  border-bottom: 3px solid #00ff33;
  transform: translateY(-100%);
  animation: radar-beam 2s infinite;
  animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
  animation-delay: 1.4s;
}

.qr-scanner .box:after,
.qr-scanner .box:before,
.qr-scanner .angle:after,
.qr-scanner .angle:before {
  content: "";
  display: block;
  position: absolute;
  width: 3vw;
  height: 3vw;

  border: 0.2rem solid transparent;
}

.qr-scanner .box:after,
.qr-scanner .box:before {
  top: 0;
  border-top-color: #00ff33;
}

.qr-scanner .angle:after,
.qr-scanner .angle:before {
  bottom: 0;
  border-bottom-color: #00ff33;
}

.qr-scanner .box:before,
.qr-scanner .angle:before {
  left: 0;
  border-left-color: #00ff33;
}

.qr-scanner .box:after,
.qr-scanner .angle:after {
  right: 0;
  border-right-color: #00ff33;
}

@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.scanner {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.tips {
  position: absolute;
  color: #fff;
  font-size: 25px;
  top: -40px;
  width: 500px;
  margin-left: -20px;
}
.danger {
  position: absolute;
  bottom: -60px;
  .el-button {
    width: 300px;
  }
}
#dlKeyword {
  position: absolute;
  z-index: -999;
  opacity: 0;
  width: 0px;
  height: 0px;
}
.el-icon-loading {
  color: #ffbb12;
  font-size: 80px;
}
</style>
