import request from '@/utils/request.js'

export default {
  // 查询当前用户商店
  appBusinessLogin: (data) => {
    return request({
      url: '/business/businessInfo/page',
      data,
      method: 'post'
    })
  },
  // 根据商店id查询商店信息
  getBusinessInfo: (id) => {
    return request({
      url: '/business/businessInfo/getById/' + id,
      method: 'post'
    })
  },
  // 商桌区域
  listByBusinessId: (id) => {
    return request({
      url: '/business/businessTableArea/listByBusinessId/' + id,
      method: 'post'
    })
  },
  // 商桌区域新增
  businessTableArea: (data) => {
    return request({
      url: '/business/businessTableArea/save',
      data,
      method: 'post'
    })
  },
  // 商桌区域删除
  businessTableAreaDel: (id) => {
    return request({
      url: '/business/businessTableArea/del/' + id,
      method: 'post'
    })
  },
  // 商桌区域批量保存
  businessTableAreaSaveBatch: (data) => {
    return request({
      url: '/business/businessTableArea/saveBatch',
      data,
      method: 'post'
    })
  },
  // 根据商家id查询聚合
  listFoodInfoVoByBusinessId: (id) => {
    return request({
      url: '/business/foodInfo/listFoodInfoVoByBusinessId/' + id,
      method: 'post'
    })
  },
  // 根据sysUserId查询
  getSysUserId: (id) => {
    return request({
      url: '/business/businessUser/getBySysUserId/' + id,
      method: 'post'
    })
  },
   // 根据Dto查询聚合
   listFoodInfoVo: (data) => {
    return request({
      url: '/business/foodInfo/listFoodInfoVo',
      method: 'post',
      data
    })
  }
}
