<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
/* 进度条加载的右边的圆圈让不显示 */
#nprogress .spinner {
    display: none !important;
}
/* 进度条颜色 */
#nprogress .bar {
    background: #0070cc;
}
/* 自定义element表格进度条 */
.el-table__body {
  overflow-x: auto
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 8px; /*滚动条宽度*/
  height: 8px; /*滚动条高度*/
}
.el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px; /*滚动条的背景区域的圆角*/
  box-shadow: inset 0 0 6px rgba(238, 238, 238, 0.3);
  background-color: #eeeeee; /*滚动条的背景颜色*/
}
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  box-shadow: inset 0 0 6px rgba(145, 143, 0143, 0.3);
  background-color: rgb(145, 143, 143); /*滚动条的背景颜色*/
}
</style>
