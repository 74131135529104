import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import component from '@/components/common'
import './assets/scss/index.scss'
import './assets/iconfont/iconfont/iconfont.css'
import './assets/iconfont/fontAwesome/font-awesome.css'
import './plugins/element.js'
import './routerBefore.js'
import 'element-ui/lib/theme-chalk/base.css'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import dayjs from 'dayjs'
import mixin from './mixin'
import directive from './directive'
import getElement from '@/utils/getElement.js'
import vconsole from 'vconsole'
Vue.directive('longpress', directive.longpress)
for (const key in getElement) {
  if (Object.hasOwnProperty.call(getElement, key)) {
    const element = getElement[key]
    Vue.use(element)
  }
}

if (navigator.userAgent.indexOf('Html5Plus') > -1 && process.env.NODE_ENV === 'development') {
     // eslint-disable-next-line no-new, new-cap
     new vconsole()
}

Vue.prototype.$eventBus = new Vue()
Vue.prototype.$dayjs = dayjs
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.mixin(mixin)
Vue.config.productionTip = false
Vue.use(component)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
