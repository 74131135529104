
import store from '@/store/index.js'
import router from '@/router/index.js'
export default function setElement (vue, element, name) {
  const dom = vue.extend(element)
  vue.prototype[`$${name}`] = (data) => {
    // eslint-disable-next-line new-cap
    const elementDom = new dom({
      store,
      router,
      data () {
        return {
          ...data
        }
      },
      methods: {
        cancel () {
          elementDom.$mount().$destroy()
          document.body.removeChild(elementDom.$mount().$el)
        }
      }
    })
    elementDom.$mount().$el.zIndex = 99999
    document.body.appendChild(elementDom.$mount().$el)
    return elementDom.$mount()
  }
}
