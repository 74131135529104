import Vue from 'vue'
import VueRouter from 'vue-router'
import permissionsRouter from './permissionsRouter.js'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登录页'
    }
  },
  {
    path: '/barcode',
    name: 'barcode',
    component: () => import('@/views/barcode/index.vue'),
    meta: {
      title: '登录页'
    }
  },
  {
    path: '/layout',
    name: 'layout',
    redirect: '/layout/home',
    component: () => import('@/views/layout/index.vue'),
    meta: {
      title: '登录页'
    },
    children: [
      ...permissionsRouter
    ]
  }

]

const router = new VueRouter({
  routes
})

export default router
