import axios from 'axios'
import { Message } from 'element-ui'
import { host } from '@/utils/url.js'
import router from '../router'
/* 请求配置 */
const service = axios.create({
  baseURL: host,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8'
  }
})

/* 请求前拦截 */
service.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
}, error => {
  Message.error({ message: '请求超时!' })
  return Promise.reject(error)
})

/* 响应拦截 */
service.interceptors.response.use(response => {
  const res = response.data
  if (res.code === 401) {
    Message.error({ message: res.msg })
    sessionStorage.removeItem('token')
    router.replace({
      path: '/login',
      query: {}
    })
    return
  }
  if (res.code !== 200) {
    Message.error({ message: res.msg || res.data })
    return Promise.reject(res)
  } else {
    return Promise.resolve(res)
  }
}, error => {
  return Promise.reject(error)
})

export default service
