import request from '@/utils/request.js'

export default {
  // 分页
  printerInfoPage: (data) => {
    return request({
      url: '/business/printerInfo/page',
      data,
      method: 'post'
    })
  },
  // 保存
  printerInfoSave: (data) => {
    return request({
      url: '/business/printerInfo/save',
      data,
      method: 'post'
    })
  },
  // 获取详情
  printerInfoGetById: (id) => {
    return request({
      url: '/business/printerInfo/getById/' + id,
      method: 'post'
    })
  },
  // 获取详情
  printerDel: (id) => {
    return request({
      url: '/business/printerInfo/del/' + id,
      method: 'post'
    })
  },
  // 打印小票
  printOrder: (data) => {
    return request({
      url: '/business/printerInfo/printOrder',
      data,
      method: 'post'
    })
  },
   // 打印加单小票
   printAddOrder: (data) => {
    return request({
      url: '/business/printerInfo/printAddOrder',
      data,
      method: 'post'
    })
  },
  // 根据商桌区域id查询
  getByBusinessTableAreaId: (businessTableAreaId) => {
    return request({
      url: '/business/printerInfo/getByBusinessTableAreaId/' + businessTableAreaId,
      method: 'post'
    })
  }
}
