import { wsUrl } from './url.js'

const messageObj = {
  message: '房间心跳------',
  type: 'heartbeat'
}

class ws {
  constructor ({ roomId, onMessageCD }) {
    this.socketTask = null
    this.reconnectTimeOut = null
    this.heartbeatInterval = null
    this.connectUserId = null
    this.roomId = roomId
    this.onMessageCD = onMessageCD
    this.connectSocket()
  }

  connectSocket () {
    this.socketTask = new WebSocket(`${wsUrl}/communication/webSocketServer/${this.roomId}`)
    console.log(this.socketTask)
    this.onOpen()
    this.onClose()
    this.onError()
    this.onMessage(this.onMessageCD)
  }

  onOpen () {
    this.socketTask.onopen = () => {
      clearInterval(this.reconnectTimeOut)
      clearInterval(this.heartbeatInterval)
      // webSocket断开重连
      this.heartbeatInterval = setInterval(() => {
        this.socketTask.send(JSON.stringify(messageObj))
      }, 1000000)
      this.socketTask.send(JSON.stringify(messageObj))
    }
  }

  onClose () {
    this.socketTask.onclose = (e) => {
      clearInterval(this.heartbeatInterval)
      clearInterval(this.reconnectTimeOut)
      console.log(e)
      if (e.code === 1000) {
        return
      }
      this.reconnectTimeOut = setInterval(() => {
        this.connectSocket()
      }, 5000)
    }
  }

  onError () {
    clearInterval(this.reconnectTimeOut)
    clearInterval(this.heartbeatInterval)
    this.socketTask.onerror = () => {
      this.reconnectTimeOut = setInterval(() => {
        this.connectSocket()
      }, 5000)
    }
  }

  onMessage (CALLBACK) {
    this.socketTask.onmessage = CALLBACK
  }
}

export default ws
