import setElement from './setElement'
import scannerVideo from '@/components/scanner'
import addOrderDialog from '@/components/addOrderDialog'
const elementConent = {}

elementConent.scannerVideo = (vue) =>
  setElement(vue, scannerVideo, 'scannerVideo')
  elementConent.addOrderDialog = (vue) =>
  setElement(vue, addOrderDialog, 'addOrderDialog')
export default elementConent
